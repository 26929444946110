.requests-chart {
    .select-container {
      margin-bottom: 1rem;
  
      .select-label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
  
      .chart-select {
        width: 200px;
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        color: #333;
        cursor: pointer;
  
        &:focus {
          outline: none;
          border-color: #8884d8;
          box-shadow: 0 0 0 2px rgba(136, 132, 216, 0.4);
        }
      }
    }
  }
  