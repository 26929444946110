@import '../Style/mainStyles.scss';


.loginTop{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height:99vh;
}

.loginBox{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:400px;
    height:40vh;
    border:2px dotted lightgrey;
    border-radius: map-get($generalStyles,threeBorderRadius);
    
    @include gadgets-to(mobile){
        width:80%;
        height:40vh;
    }
    @include gadgets-to(tablet){
        width:30%;
        height:40vh;
    }
}
.loginTitle{
    font-size:20px;
    font-family: 'Times New Roman', Times, serif;
    color:map-get($generalStyles,greenNatureDarkColor);
    margin-bottom:20px;
}
.loginInput{
    width:80%;
    height:30px;
    margin-bottom:10px;
    border:1px solid map-get($generalStyles,greenNatureDarkColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    padding-left:10px;
}
.loginButton{
    width:85%;
    height:35px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    margin-top:10px;
    cursor:pointer;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}
.confirmloginButton{
    width:85%;
    height:30px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    margin-top:10px;
    cursor:pointer;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}



.adminSpinnerContainer {

    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active
}

.adminSpinner {
    @include gadgets-to(mobile) {
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(laptop) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(desktop) {
        color: map-get($generalStyles, colorblack);
    }
}
