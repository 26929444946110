:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelTopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 99vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelTopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelTopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .panelTopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelTotalArea {
    display: grid;
    grid-template-rows: 11% 89%;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelTotalArea {
    display: grid;
    grid-template-columns: 35% 64.5%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelTotalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelTotalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .panelTotalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelAreaA {
    grid-area: a;
    border: 1px solid rgb(223, 212, 212);
    height: 11vh;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelAreaA {
    grid-area: a;
    border: 1px solid rgb(223, 212, 212);
    height: 98vh;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelAreaA {
    grid-area: a;
    border: 1px solid rgb(223, 212, 212);
    height: 98vh;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelAreaA {
    grid-area: a;
    border: 1px solid rgb(223, 212, 212);
    height: 98vh;
    border-radius: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .panelAreaA {
    grid-area: a;
    border: 1px solid rgb(223, 212, 212);
    height: 98vh;
    border-radius: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelbuttonWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelbuttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelbuttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelbuttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .panelbuttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  }
}

.panelbuttonWrapper1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 2vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelbuttonWrapper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 2vh;
  }
}

.panelbutton {
  width: 60%;
  height: 30px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: 7px;
  cursor: pointer;
}
.panelbutton:hover {
  background-color: #AEC670;
}
.panelbutton:active {
  background-color: #344C11;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelbutton {
    flex: 1;
    width: auto;
    height: 30px;
    margin-top: 1px;
    margin: 1px;
    white-space: nowrap;
  }
}

.panelbutton2 {
  width: auto;
  height: 30px;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  margin-top: 4px;
  margin-left: 2px;
  cursor: pointer;
}
.panelbutton2:hover {
  background-color: #AEC670;
}
.panelbutton2:active {
  background-color: #344C11;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelbutton2 {
    width: 90%;
    height: 30px;
    margin-top: 1px;
    margin: 1px;
    white-space: nowrap;
  }
}

.panelbutton3 {
  width: 100%;
  height: 30px;
  background-color: rgb(229, 64, 68);
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: 7px;
  cursor: pointer;
}
.panelbutton3:hover {
  background-color: rgb(229, 64, 68);
}
.panelbutton3:active {
  background-color: rgb(229, 64, 68);
}

.panelAreaB {
  grid-area: b;
  border: 1px solid rgb(223, 212, 212);
  height: 98vh;
  border-radius: 3px;
}

.panelAreaBTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}

.panelAreaBHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}

.panelAreaBDahsboardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelAreaBDahsboardItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 5vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelAreaBDahsboardItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 99%;
    height: 5vh;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelAreaBDahsboardItemTitle {
    font-size: 16px;
    font-family: var(--font-family);
    width: 100%;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelAreaBDahsboardItemTitle {
    font-size: 18px;
    font-family: var(--font-family);
    width: 57%;
    text-align: left;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelAreaBDahsboardItemTitle {
    font-size: 18px;
    font-family: var(--font-family);
    width: 57%;
    text-align: left;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelAreaBDahsboardItemTitle {
    font-size: 18px;
    font-family: var(--font-family);
    width: 57%;
    text-align: left;
    margin-left: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .panelAreaBDahsboardItemTitle {
    font-size: 18px;
    font-family: var(--font-family);
    width: 57%;
    text-align: left;
    margin-left: 10%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelAreaBDahsboardItemValue {
    font-size: 16px;
    font-family: var(--font-family);
    width: 40%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelAreaBDahsboardItemValue {
    font-size: 18px;
    font-family: var(--font-family);
    width: 40%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .panelAreaBDahsboardItemValue {
    font-size: 18px;
    font-family: var(--font-family);
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .panelAreaBDahsboardItemValue {
    font-size: 18px;
    font-family: var(--font-family);
    width: 40%;
  }
}
@media only screen and (min-width:2303px) {
  .panelAreaBDahsboardItemValue {
    font-size: 18px;
    font-family: var(--font-family);
    width: 40%;
  }
}

.panelHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelHeaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.panelHeaderTitle {
  font-size: 22px;
  font-family: var(--font-family);
  color: #344C11;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelHeaderTitle {
    font-size: 22px;
    font-family: var(--font-family);
    color: #344C11;
    margin-top: 1vh;
  }
}

.panelInvoiceStatus {
  width: 100%;
  margin-top: 2vh;
}

.panelLine {
  width: 100%;
  height: 1px;
  background-color: rgb(223, 212, 212);
  margin-top: 2vh;
}

.panelUsersTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.panelUsersHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  color: #AEC09A;
  font-size: 20px;
}

.panelUsersId {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
  height: 25px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersId {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    height: 25px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelUsersEmail {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: black;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersEmail {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelUsersName {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: black;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 16px;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersName {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
  }
}

.panelUsersListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1px;
  height: 20vh;
  max-height: 20vh;
  overflow-y: scroll;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1px;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.panelUsersWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  margin-top: 1px;
  border-bottom: 1px solid rgb(223, 212, 212);
}
.panelUsersWrapper:hover {
  border: 2px dotted gold;
}

.panelUserAdressWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 1px;
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  text-align: left;
}

.panelUserAdressTitle {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  width: 99%;
  height: 25px;
  background-color: white;
  color: black;
  font-size: 18px;
  font-family: var(--font-family);
  text-align: left;
  border-radius: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUserAdressTitle {
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 25px;
    background-color: white;
    color: black;
    font-size: 16px;
    font-family: var(--font-family);
    text-align: left;
    border: 1px dotted grey;
    border-radius: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelUserAdressTitle {
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 25px;
    background-color: white;
    color: black;
    font-size: 18px;
    text-align: left;
    border: 1px dotted grey;
    border-radius: 1px;
  }
}

.panelUserAdressValue {
  width: 70%;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: #AEC09A;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
  color: black;
  font-size: 18px;
  font-family: var(--font-family);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUserAdressValue {
    width: 95%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    color: #AEC09A;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    color: black;
    font-size: 16px;
    font-family: var(--font-family);
    text-align: left;
    padding-left: 1%;
  }
}

.panelUsersInvoiceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1px;
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  text-align: left;
}

.panelUsersInvoicesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.panelUsersHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 5%;
  color: #AEC09A;
  font-size: 20px;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5%;
    color: #AEC09A;
    font-size: 16px;
    background-color: white;
    border: 1px dotted grey;
  }
}

.panelUsersInvoiceHeader {
  width: 25%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 16px;
  border: 1px dotted grey;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersInvoiceHeader {
    width: 30%;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px dotted grey;
  }
}

.panelUsersInvoiceTopListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 15vh;
  max-height: 15vh;
  overflow-y: scroll;
}
.panelUsersInvoiceTopListWrapper::-webkit-scrollbar {
  width: 10px;
}
.panelUsersInvoiceTopListWrapper::-webkit-scrollbar-track {
  background: #344C11;
}
.panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb {
  background: #AEC670;
  border-radius: 10px;
}
.panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb:hover {
  background: #AEC670;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUsersInvoiceTopListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50vh;
    max-height: 50vh;
    overflow-y: scroll;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar {
    width: 2px;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-track {
    background: #344C11;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb {
    background: #AEC670;
    border-radius: 10px;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb:hover {
    background: #AEC670;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .panelUsersInvoiceTopListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 30vh;
    max-height: 30vh;
    overflow-y: scroll;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar {
    width: 2px;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-track {
    background: #344C11;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb {
    background: #AEC670;
    border-radius: 10px;
  }
  .panelUsersInvoiceTopListWrapper::-webkit-scrollbar-thumb:hover {
    background: #AEC670;
  }
}

.panelUsersInvoiceListWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.panelUsersInvoiceListWrapper:hover {
  background-color: #AEC670;
}

.panelUsersInvoice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1px;
  cursor: pointer;
  border-bottom: 1px solid rgb(223, 212, 212);
}
.panelUsersInvoice:hover {
  border: 2px dotted gold;
}

.panelUserInvoiceInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUserInvoiceInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.panelUserInvoiceInfoTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--font-family);
  width: 50%;
  height: 25px;
  border: 1px dotted grey;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUserInvoiceInfoTitle {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    width: 90%;
    height: 25px;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
  }
}

.panelInvoiceInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 98%;
}

.panelHeaderSearchTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}

.panelSearchUserWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelSearchUserInput {
  width: 200px;
  height: 20px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
}
.panelSearchUserInputactive, .panelSearchUserInput:focus {
  border: 1px solid #344C11;
  outline: none;
}

.panelSearchUserButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
}
.panelSearchUserButton:hover {
  background-color: #AEC670;
}
.panelSearchUserButton:active {
  background-color: #344C11;
}

.panelSearchUserListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelSearchUserListHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 20px;
}

.panelSearchUserList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 20px;
}

.panelSearchUserListId {
  width: 10%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSearchUserListId {
    width: 10%;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelSearchUserListName {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSearchUserListName {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelSearchUserListEmail {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSearchUserListEmail {
    width: calc(50% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.panelSearchUserTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelUserTurnOffWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelUserTurnOffTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelUserTurnOffTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5%;
    background-color: #344C11;
    color: #AEC09A;
    font-size: 16px;
  }
}

.panelUserTurnOffButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelUserTurnOffButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
}
.panelUserTurnOffButton:hover {
  background-color: #AEC670;
}
.panelUserTurnOffButton:active {
  background-color: #344C11;
}

.panelServicesHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.panelServicesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelServicesHeaderId {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesHeaderId {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 5%;
    background-color: white;
    border: 1px dotted grey;
    color: black;
    font-size: 14px;
  }
}

.panelServicesHeaderName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesHeaderName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 5%;
    background-color: white;
    border: 1px dotted grey;
    color: black;
    font-size: 14px;
  }
}

.panelServicesHeaderStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesHeaderStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 5%;
    background-color: white;
    border: 1px dotted grey;
    color: black;
    font-size: 14px;
  }
}

.panelServicesHeaderUpdate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesHeaderUpdate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 5%;
    background-color: white;
    border: 1px dotted grey;
    color: black;
    font-size: 14px;
  }
}

.panelServicesList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  color: black;
  font-size: 20px;
}

.panelServicesListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 3px;
}

.panelServicesId {
  width: 10%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesId {
    width: 10%;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelServicesName {
  width: calc(50% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesName {
    width: calc(50% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelServicesStatus {
  width: calc(25% - 2px);
  margin-left: 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesStatus {
    width: calc(25% - 2px);
    margin-left: 2px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelServicesUpdate {
  width: 15%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServicesUpdate {
    width: 15%;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: 1px dotted grey;
    cursor: pointer;
    text-align: left;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.panelServiceInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelServicesInfoDetailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelServiceInfoTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 25px;
  background-color: white;
  font-size: 20px;
  font-family: var(--font-family);
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelServiceInfoTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 25px;
    background-color: white;
    font-size: 16px;
    font-family: var(--font-family);
    color: black;
  }
}

.panelServiceInfoValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 5%;
  background-color: white;
  color: black;
  font-size: 20px;
}

.panelServiceInfoButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelServiceInfoButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  white-space: nowrap;
}
.panelServiceInfoButton:hover {
  background-color: #AEC670;
}
.panelServiceInfoButton:active {
  background-color: #344C11;
}

.panelCloseSignUpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelCloseSignUpTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}

.panelCloseSignUpButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelCloseSignUpButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  white-space: nowrap;
}
.panelCloseSignUpButton:hover {
  background-color: #AEC670;
}
.panelCloseSignUpButton:active {
  background-color: #344C11;
}

.panelSupportTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.panelHeaderTitle {
  font-size: 22px;
  font-family: var(--font-family);
  color: #344C11;
  margin-top: 1vh;
}

.panelSupportHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSupportHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1vh;
  }
}

.panelSupportHeaderUserId {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
  white-space: nowrap;
}

.panelSupportHeaderName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}

.panelSupportHeaderDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}

.panelSupportHeaderStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}

.panelSupportListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSupportListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1vh;
  }
}

.panelSupportList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  color: black;
  font-size: 20px;
}

.panelSupportUserId {
  width: 10%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}

.panelSupportName {
  width: 35%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}

.panelSupportDate {
  width: 30%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}

.panelSupportStatus {
  width: 12%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  white-space: nowrap;
}

.panelSupportTicketDetailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelSupportTicketDetailInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.panelSupportTicketDetailTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 25px;
  font-size: 20px;
  font-family: var(--font-family);
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .panelSupportTicketDetailTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 25px;
    background-color: white;
    font-size: 16px;
    font-family: var(--font-family);
    color: black;
  }
}

.panelSupportTicketDetailTextArea {
  width: 90%;
  height: 100px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
}

.panelSupportTicketSelectTop {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 91%;
  margin-top: 1vh;
  font-size: 14px;
  font-family: var(--font-family);
}

.panelSupportTicketDetailSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 25px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 5px;
}

.panelSupportTicketDetailButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  margin-top: 1vh;
}

.panelSupportTicketDetailButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  white-space: nowrap;
}
.panelSupportTicketDetailButton:hover {
  background-color: #AEC670;
}
.panelSupportTicketDetailButton:active {
  background-color: #344C11;
}

.panelXchangeRatesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelXchangeRatesButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  padding: 5px;
  white-space: nowrap;
}
.panelXchangeRatesButton:hover {
  background-color: #AEC670;
}
.panelXchangeRatesButton:active {
  background-color: #344C11;
}

.panelXchangeHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelXchangeHeaderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 5%;
  background-color: white;
  border: 1px dotted grey;
  color: black;
  font-size: 16px;
}

.panelXchangeListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelXchangeList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  color: black;
  font-size: 20px;
}

.panelXchangeName {
  width: 15%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}

.panelXchangeCurrencyName {
  width: 40%;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px dotted grey;
  cursor: pointer;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
}

.panelScrollLimitWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2vh;
}

.panelScrollHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelScrollHeaderTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 5%;
  background-color: #344C11;
  color: #AEC09A;
  font-size: 20px;
}

.panelScrollLimitInput {
  width: 200px;
  height: 20px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
  margin-bottom: 2vh;
}

.panelScrollLimitButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  white-space: nowrap;
}
.panelScrollLimitButton:hover {
  background-color: #AEC670;
}
.panelScrollLimitButton:active {
  background-color: #344C11;
}

.panelScrollLimitTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 20px;
  margin-bottom: 1vh;
}

.panelCreditPriceInput {
  width: 200px;
  height: 20px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
  margin-bottom: 2vh;
}

.panelCreditPriceTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 20px;
  margin-bottom: 1vh;
}

.panelCreditPriceButton {
  width: 100px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  white-space: nowrap;
}
.panelCreditPriceButton:hover {
  background-color: #AEC670;
}
.panelCreditPriceButton:active {
  background-color: #344C11;
}

.panelCreditPriceTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 20px;
  margin-bottom: 1vh;
  white-space: nowrap;
  margin-top: 5px;
}

.panelCreditPriceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
  color: #AEC09A;
}

.panelCreditCardButton {
  width: 300px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  margin-top: 1vh;
  white-space: nowrap;
}
.panelCreditCardButton:hover {
  background-color: #AEC670;
}
.panelCreditCardButton:active {
  background-color: #344C11;
}

.panelCreditCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
  color: black;
  height: 5vh;
}

.panelCreditCardInput {
  width: 200px;
  height: 20px;
  background-color: white;
  color: black;
  border: 1px solid #AEC670;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 18px;
  margin-bottom: 2vh;
  margin-top: 1vh;
}

.panelCreditCardFee {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  font-size: 20px;
  margin-bottom: 1vh;
  color: black;
}

.panelPayoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelPaylistWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 60vh;
  margin-top: 1vh;
  box-shadow: 12px 12px 10px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid rgb(223, 212, 212);
  max-height: 60vh;
  overflow-y: auto;
}

.panelPayoutList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 30px;
  background-color: white;
  color: black;
  font-size: 20px;
}

.panelPayListHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-top: 1vh;
}

.panelPayListHeader, .panelPayHeaderPayOut, .panelPayHeaderPayDate, .panelPayHeaderDate, .panelPayHeaderStatus, .panelPayHeaderAmount, .panelPayHeaderNamn, .panelPayHeaderUserid, .panelPayHeaderPayOutId {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: white;
  color: #AEC09A;
  font-size: 16px;
  margin-left: 2px;
  white-space: nowrap;
  border: 1px dotted #AEC09A;
}

.panelPayHeaderPayOutId {
  width: 100px;
}

.panelPayHeaderUserid {
  width: 60px;
}

.panelPayHeaderNamn {
  width: 150px;
}

.panelPayHeaderAmount {
  width: 80px;
}

.panelPayHeaderStatus {
  width: 60px;
}

.panelPayHeaderDate {
  width: 200px;
}

.panelPayHeaderPayDate {
  width: 200px;
}

.panelPayHeaderPayOut {
  width: 100px;
}

.panelPayoutText, .panelPayoutTextPayDate, .panelPayoutTextCreateDate, .panelPayoutTextStatus, .panelPayoutTextAmount, .panelPayoutTextName, .panelPayoutTextUserid, .panelPayoutTextPayOutId, .panelPayoutText2, .panelPayoutText1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 25px;
  background-color: white;
  color: black;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 2px;
  border-radius: 4px;
  border: 1px solid #AEC09A;
}

.panelPayoutText1 {
  width: 100px;
  cursor: pointer;
  background-color: #344C11;
  color: white;
}
.panelPayoutText1:hover {
  background-color: #AEC670;
}
.panelPayoutText1:active {
  background-color: #344C11;
}

.panelPayoutText2 {
  background-color: red;
  color: white;
  width: 100px;
}
.panelPayoutText2:hover {
  background-color: #AEC670;
}
.panelPayoutText2:active {
  background-color: #344C11;
}

.panelPayoutTextPayOutId {
  width: 100px;
  height: 25px;
}

.panelPayoutTextUserid {
  width: 60px;
  height: 25px;
}

.panelPayoutTextName {
  width: 150px;
  height: 25px;
}

.panelPayoutTextAmount {
  width: 80px;
  height: 25px;
}

.panelPayoutTextStatus {
  width: 60px;
  height: 25px;
}

.panelPayoutTextCreateDate {
  width: 200px;
  height: 25px;
}

.panelPayoutTextPayDate {
  width: 200px;
  height: 25px;
}

.panelColumnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.panelGetAccountBalanceButton {
  width: 300px;
  height: 25px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 2px;
  margin-top: 1vh;
  white-space: nowrap;
}
.panelGetAccountBalanceButton:hover {
  background-color: #AEC670;
}
.panelGetAccountBalanceButton:active {
  background-color: #344C11;
}

.panelGetAccountBalanceTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  background-color: white;
  color: #AEC09A;
  font-size: 16px;
  margin-bottom: 1vh;
  white-space: nowrap;
}

.panelGetAccountBalanceValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 10%;
  height: 5%;
  background-color: white;
  color: black;
  font-size: 20px;
  margin-bottom: 1vh;
  white-space: nowrap;
  border: 1px dotted #AEC09A;
}

.panelPayoutButton {
  width: 100px;
}/*# sourceMappingURL=panel.css.map */