:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.loginTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 99vh;
}

.loginBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 40vh;
  border: 2px dotted lightgrey;
  border-radius: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .loginBox {
    width: 80%;
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .loginBox {
    width: 30%;
    height: 40vh;
  }
}

.loginTitle {
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  color: #344C11;
  margin-bottom: 20px;
}

.loginInput {
  width: 80%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #344C11;
  border-radius: 3px;
  padding-left: 10px;
}

.loginButton {
  width: 85%;
  height: 35px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
}
.loginButton:hover {
  background-color: #AEC670;
}
.loginButton:active {
  background-color: #344C11;
}

.confirmloginButton {
  width: 85%;
  height: 30px;
  background-color: #344C11;
  color: white;
  border: none;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
}
.confirmloginButton:hover {
  background-color: #AEC670;
}
.confirmloginButton:active {
  background-color: #344C11;
}

.adminSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 1;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .adminSpinner {
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .adminSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .adminSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .adminSpinner {
    color: black;
  }
}/*# sourceMappingURL=Login.css.map */