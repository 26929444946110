@import '../Style/mainStyles.scss';


.panelTopWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    // align-items:center;
     width:100%;
     height:99vh;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}

    
    .panelTotalArea {
        @include gadgets-to(mobile) {
            display: grid;
           grid-template-rows:11% 89%;
            grid-template-areas:
                "a"
                "b";
            width: 100%;
            height:auto;
            object-fit: cover;
    
    
    
        }
    
            @include gadgets-to(tablet) {
                display: grid;
                grid-template-columns: 35% 64.5%; // Adjust as needed
                grid-template-rows: auto auto;
                grid-template-areas:
                  "a b";
                column-gap: 5px;
                width:100%;
                height:auto;
                margin-top:5px;
                object-fit: cover;
    
         
              }
              @include gadgets-to(laptop) {
                display: grid;
                grid-template-columns:25% 75%;
                grid-template-rows: auto auto;
                grid-template-areas:
                  "a b";
          
                  column-gap:7px;
                width: 100%;
                height:auto;
                margin-top:5px;
                object-fit: cover;
            
            }
            @include gadgets-to(desktop) {
                display: grid;
                grid-template-columns:25% 75%;
                grid-template-rows: auto auto;
                grid-template-areas:
                  "a b";
           
                  column-gap:7px;
                width: 100%;
                height:auto;
                margin-top:5px;
                object-fit: cover;
            
            }
           
        
            @include gadgets-to(largeDesktop) {
                display: grid;
                grid-template-columns:25% 75%;
                grid-template-rows: auto auto;
                grid-template-areas:
                  "a b";
             
                  column-gap:7px;
                width: 100%;
                height:auto;
                margin-top:5px;
                object-fit: cover;
            
            }
    }


    .panelAreaA{
        @include gadgets-to(mobile) {

        grid-area: a;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:11vh;
        border-radius: map-get($generalStyles,threeBorderRadius);

    }
        
    @include gadgets-to(tablet) {
        grid-area: a;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:98vh;
        border-radius: map-get($generalStyles,threeBorderRadius);
    }
    @include gadgets-to(laptop) {
        grid-area: a;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:98vh;
        border-radius: map-get($generalStyles,threeBorderRadius);
    }
    @include gadgets-to(desktop) {
        grid-area: a;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:98vh;
        border-radius: map-get($generalStyles,threeBorderRadius);
    }
    @include gadgets-to(largeDesktop) {
        grid-area: a;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:98vh;
        border-radius: map-get($generalStyles,threeBorderRadius);
    }
}

    .panelbuttonWrapper{
        @include gadgets-to(mobile) {

        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        align-items:center;
        justify-content:center;
       // margin-top:2vh;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        margin-top:2vh;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        margin-top:2vh;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        margin-top:2vh;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        margin-top:2vh;
    }
}

.panelbuttonWrapper1{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    width:90%;
    margin-top:2vh;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        width:90%;
        margin-top:2vh;
    }
}

    .panelbutton{
        width:60%;
        height:30px;
        background-color:map-get($generalStyles,greenNatureDarkColor);
        color:white;
        border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:7px;
        cursor:pointer;
        // hover
        &:hover{
            background-color:map-get($generalStyles,greenNatureLightColor);
        }
        //active
        &:active{
            background-color:map-get($generalStyles,greenNatureDarkColor);
        }
        @include gadgets-to(mobile) {
            flex:1;
            width:auto;
            height:30px;
            margin-top:1px;
            margin:1px;
            // no text wrap
            white-space: nowrap;
 
        }
    }
    .panelbutton2{
        width:auto;
        height:30px;
        background-color:white;
        color:black;
        border:1px dotted grey;
     //   border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:4px;
        margin-left:2px;
        cursor:pointer;
        // hover
        &:hover{
            background-color:map-get($generalStyles,greenNatureLightColor);
        }
        //active
        &:active{
            background-color:map-get($generalStyles,greenNatureDarkColor);
        }
    @include gadgets-to(mobile) {
        //flex:1;
        width:90%;
        height:30px;
        margin-top:1px;
        margin:1px;
        // no text wrap
        white-space: nowrap;
    }
}

    .panelbutton3{
        width:100%;
        height:30px;
        background-color:map-get($generalStyles,pinkRed);
        color:white;
        border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:7px;
        cursor:pointer;
        // hover
        &:hover{
            background-color:map-get($generalStyles,pinkRed);
        }
        //active
        &:active{
            background-color:map-get($generalStyles,pinkRed);
        }
    }

    .panelAreaB{
        grid-area: b;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        height:98vh;
        border-radius: map-get($generalStyles,threeBorderRadius);
    }
    .panelAreaBTitle{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        height:5%;
        background-color:map-get($generalStyles,greenNatureDarkColor);
        color:map-get($generalStyles,greenNatureLightGray);
        font-size:20px;
    }
    .panelAreaBHeader{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        height:5%;
        background-color:map-get($generalStyles,greenNatureDarkColor);
        color:map-get($generalStyles,greenNatureLightGray);
        font-size:20px;
    }
    .panelAreaBDahsboardWrapper{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
        margin-top:1vh;
    }
    .panelAreaBDahsboardItem{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around;
        width:90%;
        height:5vh;
  @include gadgets-to(mobile) {
    display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around;
        width:99%;
        height:5vh;
    // words cannot be wrapped
    white-space: nowrap;
    }
}

    .panelAreaBDahsboardItemTitle{
     @include gadgets-to(mobile) {
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        width:100%;
        text-align:left;
       // margin-left:10%;
    }
    @include gadgets-to(tablet) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:57%;
        text-align:left;
        margin-left:10%;
    }
    @include gadgets-to(laptop) {
        font-size:18px;
     font-family:map-get($generalStyles,fontFamily);
     width:57%;
     text-align:left;
     margin-left:10%;
    }
    @include gadgets-to(desktop) {
        font-size:18px;
     font-family:map-get($generalStyles,fontFamily);
     width:57%;
     text-align:left;
     margin-left:10%;
    }
    @include gadgets-to(largeDesktop) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:57%;
        text-align:left;
        margin-left:10%;
    }

}

    .panelAreaBDahsboardItemValue{
        @include gadgets-to(mobile) {
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        width:40%;
    }
    @include gadgets-to(tablet) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:40%;
    }
    @include gadgets-to(laptop) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:40%;
    }
    @include gadgets-to(desktop) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:40%;
    }
    @include gadgets-to(largeDesktop) {
        font-size:18px;
        font-family:map-get($generalStyles,fontFamily);
        width:40%;
    }
}
    .panelHeaderWrapper{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
        @include gadgets-to(mobile) {
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;
            width:100%;
        }
    }
    .panelHeaderTitle{
        font-size:22px;
        font-family:map-get($generalStyles,fontFamily);
        color:map-get($generalStyles,greenNatureDarkColor);
        margin-top:1vh;
        @include gadgets-to(mobile) {
            font-size:22px;
            font-family:map-get($generalStyles,fontFamily);
            color:map-get($generalStyles,greenNatureDarkColor);
            margin-top:1vh;
        }
    }
    .panelInvoiceStatus{
        width:100%;
        margin-top:2vh;
    }

    .panelLine{
        width:100%;
        height:1px;
        background-color:map-get($generalStyles,datalistBorderColor);
        margin-top:2vh;
    }
.panelUsersTopWrapper{
    display:flex;
  flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
}
.panelUsersHeader{
   display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
 //   background-color:map-get($generalStyles,greenNatureDarkColor);
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}
.panelUsersId{
    display:flex;
    flex-direction:row;
    align-items:center;
   // justify-content:center;
    width:20%;
    height:25px;
    background-color:white;
    color:black;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    align-items:center;
    //justify-content:center;
    width:20%;
    height:25px;
    background-color:white;
    color:black;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
}
    
}
.panelUsersEmail{
    width:calc(40% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    //justify-content:center;
    background-color:white;
    color:black;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    @include gadgets-to(mobile) {
        width:calc(40% - 2px);
        margin-left:2px;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        //justify-content:center;
        background-color:white;
        color:black;
        border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}
.panelUsersName{
    width:calc(40% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
  //  justify-content:center;
    background-color:white;
    color:black;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 16px;
    // no text break
    white-space: nowrap;
    @include gadgets-to(mobile) {
        width:calc(40% - 2px);
        margin-left:2px;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        //justify-content:center;
        background-color:white;
        color:black;
        border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 14px;
        // no text wrap
        white-space: nowrap;
    }
}
.panelUsersListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
   // justify-content:center;
    width:100%;
    margin-top:1px;
    height:20vh;
    max-height:20vh;
    overflow-y:scroll;
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
   // justify-content:center;
    width:100%;
    margin-top:1px;
    height:80vh;
    max-height:80vh;
    overflow-y:scroll;

}


}
.panelUsersWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    margin-top:1px;
    // underline
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
    // hover
    &:hover{
      border:2px dotted gold;
    }
    

}

.panelUserAdressWrapper{
    display:flex;
    flex-direction:column;
   // align-items:center;
   // justify-content:center;
    width:90%;
    margin-top:1px;
    height:50vh;
    max-height:50vh;
    overflow-y:scroll;
    text-align: left;
}
.panelUserAdressTitle{
    padding-left:5px;
    display:flex;
    flex-direction:row;
  //  align-items:center;
   // justify-content:center;
    width:99%;
    height:25px;
    background-color:white;
    color:black;
    font-size:18px;
    font-family:map-get($generalStyles,fontFamily);
    text-align: left;
    border-radius: map-get($generalStyles,sixBorderRadius);

    @include gadgets-to(mobile) {
        padding-left:5px;
        display:flex;
        flex-direction:row;
        align-items:center;
        //justify-content:center;
        width:95%;
        height:25px;
        background-color: white;
        color:black;
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        text-align: left;
        //border-radius: map-get($generalStyles,sixBorderRadius);
       border: 1px dotted grey;
       border-radius:1px;
       // no text wrap
         white-space: nowrap;
         //text overlow
            overflow: hidden;
            text-overflow: ellipsis;

   
    }
    @include gadgets-to(tablet) {
        padding-left:5px;
        display:flex;
        flex-direction:row;
        align-items:center;
       // justify-content:center;
        width:100%;
        height:25px;
        background-color: white;
        color:black;
        font-size:18px;
        text-align: left;
        //border-radius: map-get($generalStyles,sixBorderRadius);
       border: 1px dotted grey;
       border-radius:1px;
   
    }
}

.panelUserAdressValue{
    width:70%;
    height:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    //justify-content:center;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    color:black;
    font-size:18px;
    font-family:map-get($generalStyles,fontFamily);
    @include gadgets-to(mobile) {
        width:95%;
        height:20px;
        display:flex;
        flex-direction:row;
        align-items:center;
       // justify-content:center;
        background-color:white;
        color:map-get($generalStyles,greenNatureLightGray);
        border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        color:black;
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        text-align: left;
        padding-left:1%;
    }
}

.panelUsersInvoiceWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1px;
    height:50vh;
    max-height:50vh;
    overflow-y:scroll;
    text-align: left;
}
.panelUsersInvoicesWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;

}
.panelUsersHeaderWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    height:5%;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
    background-color: white;
  //  border:1px dotted grey;
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:16px;
    background-color: white;
    border:1px dotted grey;
}
}
.panelUsersInvoiceHeader{
    width:25%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:none;
    //border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 16px;
    border:1px dotted grey;
    @include gadgets-to(mobile) {
        width:30%;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:none;
        //border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 14px;
        border:1px dotted grey;
    }
}
.panelUsersInvoiceTopListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
   // justify-content:center;
    width:100%;
    height:15vh;
    max-height:15vh;
    overflow-y:scroll;
    // webkit scrollbar style
    &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: map-get($generalStyles,greenNatureDarkColor);
      }
      &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,greenNatureLightColor);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: map-get($generalStyles,greenNatureLightColor);
      }
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
   // justify-content:center;
    width:100%;
    height:50vh;
    max-height:50vh;
    overflow-y:scroll;
    // webkit scrollbar style
    &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background: map-get($generalStyles,greenNatureDarkColor);
      }
      &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,greenNatureLightColor);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: map-get($generalStyles,greenNatureLightColor);
      }
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    align-items:center;
   // justify-content:center;
    width:90%;
    height:30vh;
    max-height:30vh;
    overflow-y:scroll;
    // webkit scrollbar style
    &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background: map-get($generalStyles,greenNatureDarkColor);
      }
      &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,greenNatureLightColor);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: map-get($generalStyles,greenNatureLightColor);
      }
}
}
.panelUsersInvoiceListWrapper{
    display:flex;
    flex-direction:row;
   /// align-items:center;
   // justify-content:center;
    width:100%;
    //hover
    &:hover{
      background-color: map-get($generalStyles,greenNatureLightColor);
    }



}

.panelUsersInvoice{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1px;
    cursor:pointer;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
    // hover
    &:hover{
      border:2px dotted gold;
    }

}
.panelUserInvoiceInfoWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
   //7 justify-content:center;
    width:100%;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items:center;
       //7 justify-content:center;
        width:100%;
    }


}
.panelUserInvoiceInfoTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
   // justify-content:center;
font-family:var(--font-family);
width:50%;
height:25px;
border:1px dotted grey;
cursor:pointer;
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
   // align-items:center;
  //  justify-content:center;
    font-family:var(--font-family);
    width:90%;
    height:25px; 
    border:1px dotted grey;
    cursor:pointer;
    text-align: left;
}
}
.panelInvoiceInfoWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:98%;
    
}
.panelHeaderSearchTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}
.panelSearchUserWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelSearchUserInput{
    width:200px;
    height:20px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    // Focus styles
&active, &:focus {
    border: 1px solid map-get($generalStyles,greenNatureDarkColor);
    outline: none;

}
}
.panelSearchUserButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}

.panelSearchUserListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelSearchUserListHeader{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color: white;
  //  border:2px dotted grey;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}
.panelSearchUserList{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color: white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}

.panelSearchUserListId{
    width:10%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 16px;
    @include  gadgets-to(mobile) {
        width:10%;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
      //  border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}
.panelSearchUserListName{
    width:calc(40% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
   // border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 16px;
    @include gadgets-to(mobile) {
        width:calc(40% - 2px);
        margin-left:2px;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
       // border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}

.panelSearchUserListEmail{
    width:calc(40% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 16px;
@include gadgets-to(mobile) {
    width:calc(50% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}
.panelSearchUserTop{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}

.panelUserTurnOffWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelUserTurnOffTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width:100%;
        height:5%;
        background-color:map-get($generalStyles,greenNatureDarkColor);
        color:map-get($generalStyles,greenNatureLightGray);
        font-size:16px;
    }
}

.panelUserTurnOffButtonWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelUserTurnOffButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}

.panelServicesHeaderWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
}
.panelServicesWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelServicesHeaderId{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:10%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width:10%;
        height:5%;
        background-color:white;
        border:1px dotted grey;
        color:black;
        font-size:14px;
    }
}
.panelServicesHeaderName{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:50%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width:50%;
        height:5%;
        background-color:white;
        border:1px dotted grey;
        color:black;
        font-size:14px;
    }
}
.panelServicesHeaderStatus{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:25%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:25%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:14px;

}
}

.panelServicesHeaderUpdate{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:15%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:15%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:14px;
}
}


.panelServicesList{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    color:black;
    font-size:20px;
}
.panelServicesListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:3px;
}
.panelServicesId{
    width:10%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    @include gadgets-to(mobile) {
        width:10%;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
      //  border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}
.panelServicesName{
    width:calc(50% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    background-color:white;
    color:black;
    border:1px dotted grey;
   // border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    // max length
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include gadgets-to(mobile) {
        width:calc(50% - 2px);
        margin-left:2px;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
       // border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}
.panelServicesStatus{
    width:calc(25% - 2px);
    margin-left:2px;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    @include gadgets-to(mobile) {
        width:calc(25% - 2px);
        margin-left:2px;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
       // border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}
.panelServicesUpdate{
    width:15%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    @include gadgets-to(mobile) {
        width:15%;
        height:25px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        background-color:white;
        color:black;
        border:1px dotted grey;
      //  border-radius: map-get($generalStyles,sixBorderRadius);
       // margin-top:7px;
        cursor:pointer;
        text-align:left;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 12px;
    }
}

.panelServiceInfoWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelServicesInfoDetailWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelServiceInfoTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    height:25px;
    background-color: white;
    font-size:20px;
    font-family:map-get($generalStyles,fontFamily);
    color:black;

    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:flex-start;
        width:100%;
        height:25px;
        background-color: white;
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        color:black;

    }
}
.panelServiceInfoValue{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:50%;
    height:5%;
    background-color:white;
    color:black;
    font-size:20px;
}
.panelServiceInfoButtonWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelServiceInfoButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}

.panelCloseSignUpWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelCloseSignUpTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}
.panelCloseSignUpButtonWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelCloseSignUpButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}
.panelSupportTop{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
}
.panelHeaderTitle{
    font-size:22px;
    font-family:map-get($generalStyles,fontFamily);
    color:map-get($generalStyles,greenNatureDarkColor);
    margin-top:1vh;
}
.panelSupportHeaderWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width:100%;
        margin-top:1vh;
    }
}
.panelSupportHeaderUserId{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:10%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
    // no text wrap
    white-space: nowrap;
}
.panelSupportHeaderName{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:35%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
}
.panelSupportHeaderDate{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:30%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
}
.panelSupportHeaderStatus{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:12%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
}
.panelSupportListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
        margin-top:1vh;
    }
}
.panelSupportList{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    color:black;
    font-size:20px;
}
.panelSupportUserId{
    width:10%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
}
.panelSupportName{
    width:35%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
}
.panelSupportDate{
    width:30%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
}
.panelSupportStatus{
    width:12%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
  //  border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    white-space: nowrap;
}
.panelSupportTicketDetailWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelSupportTicketDetailInfo{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
}
.panelSupportTicketDetailTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:50%;
    height:25px;
   // background-color:grey;
    font-size:20px;
    font-family:map-get($generalStyles,fontFamily);
    color:black;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:flex-start;
        width:90%;
        height:25px;
        background-color: white;
        font-size:16px;
        font-family:map-get($generalStyles,fontFamily);
        color:black;
    }
}
.panelSupportTicketDetailTextArea{
    width:90%;
    height:100px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    // Focus styles

}
.panelSupportTicketSelectTop{
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    justify-content:flex-end;
    width:91%;
    margin-top:1vh;
    font-size: 14px;
    font-family: map-get($generalStyles,fontFamily);
}
.panelSupportTicketDetailSelect{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:50%;
    height:25px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    margin-top:5px;
}
.panelSupportTicketDetailButtonWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-end;
    width:90%;
    margin-top:1vh;
}
.panelSupportTicketDetailButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}

.panelXchangeRatesWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelXchangeRatesButton{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    padding:5px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}
.panelXchangeHeaderWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelXchangeHeaderItem{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:25%;
    height:5%;
    background-color:white;
    border:1px dotted grey;
    color:black;
    font-size:16px;
 
}
.panelXchangeListWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelXchangeList{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    color:black;
    font-size:20px;
}
.panelXchangeName{
    width:15%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
}

.panelXchangeCurrencyName{
    width:40%;
    height:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:white;
    color:black;
    border:1px dotted grey;
    cursor:pointer;
    text-align:left;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
}

.panelScrollLimitWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:2vh;
}
.panelScrollHeaderWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelScrollHeaderTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:99%;
    height:5%;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
}
.panelScrollLimitInput{
    width:200px;
    height:20px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    // Focus styles
    margin-bottom:2vh;
}
.panelScrollLimitButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}

.panelScrollLimitTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:60%;
    height:5%;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
    margin-bottom:1vh;
}

.panelCreditPriceInput{
    width:200px;
    height:20px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    // Focus styles
    margin-bottom:2vh;
}
.panelCreditPriceTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:60%;
    height:5%;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
    margin-bottom:1vh;
}
.panelCreditPriceButton{
    width:100px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}
.panelCreditPriceTitle{
    display:flex;
    flex-direction:row;
    //align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:20px;
    margin-bottom:1vh;
    white-space: nowrap;
    margin-top:5px;
}
.panelCreditPriceWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
    color:map-get($generalStyles,greenNatureLightGray);
}
.panelCreditCardButton{
    width:300px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    margin-top:1vh;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}
.panelCreditCardWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
  //  color:map-get($generalStyles,greenNatureLightGray);
     color:black;
     height:5vh;
}
.panelCreditCardInput{
    width:200px;
    height:20px;
    background-color:white;
    color:black;
    border:1px solid map-get($generalStyles,greenNatureLightColor);
    border-radius: map-get($generalStyles,threeBorderRadius);
    //margin-top:1vh;
    padding-left:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    // Focus styles
    margin-bottom:2vh;
    margin-top:1vh;
}
.panelCreditCardFee{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    font-size:20px;
    margin-bottom:1vh;
    color:black;

}
.panelPayoutWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelPaylistWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    //justify-content:center;
    width:95%;
    height:60vh;
    margin-top:1vh;
    box-shadow: 12px 12px 10px 6px rgba(0,0,0,0.2);
    border-radius:8px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    max-height:60vh;
    overflow-y:auto;
}
.panelPayoutList{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:95%;
    height:30px;
    background-color:white;
    color:black;
    font-size:20px; 
    
}
.panelPayListHeaderWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:95%;
    margin-top:1vh;
}
.panelPayListHeader{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:30px;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:16px;
    margin-left:2px;
    white-space: nowrap;
    border:1px dotted map-get($generalStyles,greenNatureLightGray);
}

.panelPayHeaderPayOutId{
@extend .panelPayListHeader;
width:100px;
}
.panelPayHeaderUserid{
@extend .panelPayListHeader;
width:60px;
}
.panelPayHeaderNamn{
@extend .panelPayListHeader;
width:150px;
}
.panelPayHeaderAmount{
@extend .panelPayListHeader;
width:80px;
}
.panelPayHeaderStatus{
@extend .panelPayListHeader;
width:60px;
}
.panelPayHeaderDate{
@extend .panelPayListHeader;
width:200px;
}
.panelPayHeaderPayDate{
@extend .panelPayListHeader;
width:200px;
}
.panelPayHeaderPayOut{
@extend .panelPayListHeader;
width:100px;
}







.panelPayoutText{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100px;
    height:25px;
    background-color:white;
    color:black;
   font-size:14px;
    white-space: nowrap;
    margin-left:2px;
    border-radius:4px;
    border:1px solid map-get($generalStyles,greenNatureLightGray);
}
.panelPayoutText1{
    @extend .panelPayoutText;
    width:100px;
    cursor:pointer;
    background-color: map-get($generalStyles,greenNatureDarkColor);
    color:white;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}
.panelPayoutText2{
    @extend .panelPayoutText;
    background-color:red;
    color:white;
    width:100px;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
}


.panelPayoutTextPayOutId{
    @extend .panelPayoutText;
    width:100px;
    height:25px;
}

.panelPayoutTextUserid{
    @extend .panelPayoutText;
    width:60px;
    height:25px;
}

.panelPayoutTextName{
    @extend .panelPayoutText;
    width:150px;
    height:25px;
}

.panelPayoutTextAmount{
    @extend .panelPayoutText;
    width:80px;
    height:25px;
}

.panelPayoutTextStatus{
    @extend .panelPayoutText;
    width:60px;
    height:25px;
}
.panelPayoutTextCreateDate{
    @extend .panelPayoutText;
    width:200px;
    height:25px;
}
.panelPayoutTextPayDate{
    @extend .panelPayoutText;
    width:200px;
    height:25px;
}


.panelColumnWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-top:1vh;
}
.panelGetAccountBalanceButton{
    width:300px;
    height:25px;
    background-color:map-get($generalStyles,greenNatureDarkColor);
    color:white;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
   // margin-top:7px;
    cursor:pointer;
    margin-left:2px;
    margin-top:1vh;
    // hover
    &:hover{
        background-color:map-get($generalStyles,greenNatureLightColor);
    }
    //active
    &:active{
        background-color:map-get($generalStyles,greenNatureDarkColor);
    }
    // no text wrap
    white-space: nowrap;
}
.panelGetAccountBalanceTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:5%;
    background-color:white;
    color:map-get($generalStyles,greenNatureLightGray);
    font-size:16px;
    margin-bottom:1vh;
    white-space: nowrap;
}
.panelGetAccountBalanceValue{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:10%;
    height:5%;
    background-color:white;
    color:black;
    font-size:20px;
    margin-bottom:1vh;
    white-space: nowrap;
    border:1px dotted map-get($generalStyles,greenNatureLightGray);
}

.panelPayoutButton{
width:100px;
}